<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'
import { sumdate } from '@/plugins/filters'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(16)
    const fontBody = ref(14)
    const frame = ref('')
    //const textData = ref('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    const topic = ref(route.value.query.topic || '1')
    i18n.locale = 'th'
    if (route.value.query.type == 'opd') {
      prints.printsCertSickOpd({
        opd_id: route.value.query.id,
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    } else {
      prints.printsCertSick({
        sick_id: route.value.query.id.split(','),
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    }

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      })
      doc.setProperties({
        title: `ใบรับรองแพทย์ ${data.length == 1 ? `${data[0].customer_id} ${data[0].customer_fullname} - ${data[0].opd_date}` : ''}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      for (let i = 0; i < data.length; i++) {
        const spacer = 5
        let total = 10

        doc.addImage(require('./CertSickNewTh2.png'), 'JPEG', 0, 0, 210, 297)
        doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 20, 4, 28, 25)
        doc.setFontSize(fontTitle.value)
        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${data[i].shop_name} ${data[i].shop_nature}`, 50, total)
        doc.text(`${i18n.t('a_number')} ${data[i].sick_code}`, 188, total, null, null, 'right')

        total += spacer
        const text = `${data[i].shop_address} ${data[i].shop_district}  ${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`
        const text1 = doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(text, 165)
        for (let x = 0; x < text1.length; x ++) {
          let positionStarter = 0
          positionStarter = 50
          doc.text(positionStarter, total, text1[x])
          total += spacer * 2
        }

        // if (topic.value == '2') {
        //   doc.text(`${i18n.t('checkPaper')}`, 40, total)
        // } else {
        //   doc.text(`${i18n.t('medical_certificate')}`, 40, total)
        // }
        doc.setFontSize(fontBody.value)
        doc.setFont('TH-Niramit', 'normal')
        total = 32
        doc.text(`${data[i].user_fullname}`, 154, total)
        total = 49
        doc.text(`${data[i].customer_fullname}`, 55, total)

        doc.text(`${data[i].customer_id}`, 156, total)
        total = 65
        doc.text(`${sumdate(data[i].opd_date)}`, 105, total)
        total = 81.5
        const cc = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].opd_cc, 165)
        cc.forEach(el => {
          doc.text(el, 21, total)
          total += spacer + 3
        }) 

        total = 113.5
        const dx = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].opd_dx, 165)
        dx.forEach(el => {
          doc.text(el, 21, total)
          total += spacer + 3
        })

        total = 194
        doc.text(`${data[i].sick_start_d || ''}`, 53, total)
        doc.text(`${data[i].sick_start_m || ''}`, 58, total)
        doc.text(`${data[i].sick_start_y || ''}`, 75, total)
        doc.text(`${data[i].sick_end_d || ''}`, 93, total)
        doc.text(`${data[i].sick_end_m || ''}`, 98, total)
        doc.text(`${data[i].sick_end_y || ''}`, 115, total)
        total = 235
        doc.text(`${data[i].user_fullname}`, 138, total, null, null, 'center')
        total = 242
        doc.text(`${data[i].user_license || ''}`, 156, total, null, null, 'left')
        total += spacer + 2.4
        doc.text(`${sumdate(data[i].print_date)}`, 158, total, null, null, 'left')
        total += spacer
      }

      frame.value = doc.output('datauristring', { filename: '' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
